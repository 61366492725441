import React, { Suspense, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Background, Logo } from "../assets";
import { IoTrainOutline, IoGitBranch, IoClose } from "react-icons/io5";
import { RxDashboard } from "react-icons/rx";
import { HiMenu, HiOutlineLibrary, HiUser, HiUsers } from "react-icons/hi";
import { TfiLayoutMediaLeftAlt, TfiSettings } from "react-icons/tfi";
import Loading from "./Loading";

const Sidebar = ({ children }) => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  const MenuList = [
    {
      id: "dsh-1",
      name: "dashboard",
      to: "dashboard",
      icon: <RxDashboard />,
    },
    {
      id: "tls-93",
      name: "train list",
      to: "train",
      icon: <IoTrainOutline />,
    },
    // {
    //   id: "tstl-44",
    //   name: "station list",
    //   to: "station",
    //   icon: <HiOutlineLibrary />,
    // },
    // {
    //   id: "mrg-81",
    //   name: "media & storage",
    //   to: "mediastorage",
    //   icon: <TfiLayoutMediaLeftAlt />,
    // },
    {
      id: "rts-72",
      name: "routes",
      to: "routes",
      icon: <IoGitBranch />,
    },
    {
      id: "usr-58",
      name: "users",
      to: "users",
      icon: <HiUser />,
    },
    {
      id: "clt-18",
      name: "client",
      to: "clients",
      icon: <HiUsers />,
    },
    {
      id: "stg-35",
      name: "settings",
      to: "settings",
      icon: <TfiSettings />,
    },
  ];

  const MenuSide = () => {
    return (
      <div
        className={`w-[250px] h-full absolute left-[10px] lg:relative rounded-[20px] p-[10px] bg-LIGHT shadow-lg flex flex-col items-center z-20`}
      >
        <img
          src={Logo}
          alt="Viaeight Logo"
          className="w-full max-w-[150px] mt-[10px]"
        />
        <br />
        <div className="w-full h-full flex flex-col items-center gap-2">
          {MenuList.map((x) => {
            return (
              <NavLink
                id={x.id}
                key={x.id}
                to={x.to}
                className={({ isActive }) =>
                  `${isActive
                    ? "bg-gradient-to-b from-[#00B7CE] to-[#00D6D6] text-LIGHT"
                    : "bg-SECONDARY_LIGHT text-SECONDARY"
                  } w-full p-[10px] rounded-full text-sm flex items-center gap-2 hover:text-base active:opacity-40 ease-linear duration-200`
                }
              >
                {x.icon}
                <span>{x.name}</span>
              </NavLink>
            );
          })}
        </div>

        {open && (
          <button
            className="w-full p-[10px] rounded-full text-sm flex items-center gap-2 bg-SECONDARY_LIGHT text-SECONDARY hover:bg-gradient-to-b from-[#00B7CE] to-[#00D6D6] hover:text-LIGHT ease-linear duration-200 "
            onClick={() => setOpen((prev) => !prev)}
          >
            <IoClose />
            <span>Close</span>
          </button>
        )}
      </div>
    );
  };
  return (
    <main
      className="h-screen w-screen flex-1 flex justify-center items-center p-[20px] gap-6"
      style={{
        background: `url(${Background}) repeat rgba(0,183,206,0.9)`,
      }}
    >
      {window.innerWidth >= 1024 && <MenuSide />}
      {open && <MenuSide />}

      <div className="w-full h-full rounded-[20px] bg-LIGHT relative shadow-lg flex flex-col">
        <div className=" absolute top-0 right-0 rounded-bl-[20px] rounded-tr-[20px] px-[20px] py-[10px] bg-SUCCESS text-SECONDARY text-sm capitalize">
          {pathname.replace("/", "")}
        </div>
        <button
          className="lg:hidden flex absolute top-0 left-0 rounded-br-[20px] rounded-tl-[20px] px-[20px] py-[10px] bg-SUCCESS text-SECONDARY text-sm capitalize"
          onClick={() => setOpen((prev) => !prev)}
        >
          <HiMenu />
        </button>
        <Suspense fallback={<Loading />}>{children}</Suspense>
      </div>
    </main>
  );
};

export default Sidebar;
