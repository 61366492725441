import React, { createContext, useEffect, useState } from "react";

export const ContextWrapper = createContext(null);

const ContextProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState();
  const token = localStorage.getItem("token");

  useEffect(() => {
    setLoggedIn(token);

    return () => {};
  }, []);
  const value = {
    loggedIn,
    setLoggedIn,
  };
  return (
    <ContextWrapper.Provider value={value}>{children}</ContextWrapper.Provider>
  );
};

export default ContextProvider;
