//! React
import React, { useContext } from "react";
import "react-toastify/dist/ReactToastify.css";

//! Context
import { ContextWrapper } from "./context/ContextProvider";

//! Components
import Sidebar from "./components/Sidebar";

//! Routes
import Routes from "./routes/Routes";
import UnRoutes from "./routes/UnRoutes";

function App() {
  //* Variable Declare
  /**
   * State Worker
   * @param {useState} State_Management - useStates
   */

  const { loggedIn } = useContext(ContextWrapper);

  //* Render
  if (loggedIn) {
    return (
      <Sidebar>
        <Routes />
      </Sidebar>
    );
  } else {
    return <UnRoutes />;
  }
}

export default App;
