import React, { lazy, Suspense } from "react";
import { Route, Routes as Routex } from "react-router-dom";
import Loading from "../components/Loading";
const Dashboard = lazy(() =>
  wait(1000).then(() => import("../pages/Dashboard"))
);
const Clients = lazy(() => wait(1000).then(() => import("../pages/Clients")));
const ClientsDetail = lazy(() =>
  wait(1000).then(() => import("../pages/ClientsDetail"))
);

const MediaStorage = lazy(() =>
  wait(1000).then(() => import("../pages/MediaStorage"))
);
const MediaStorageAdd = lazy(() =>
  wait(1000).then(() => import("../pages/MediaStorageAdd"))
);
const Routen = lazy(() => wait(1000).then(() => import("../pages/Routen")));
const RoutenAdd = lazy(() =>
  wait(1000).then(() => import("../pages/RoutenAdd"))
);
const RoutenDetail = lazy(() =>
  wait(1000).then(() => import("../pages/RoutenDetail"))
);

const Settings = lazy(() => wait(1000).then(() => import("../pages/Settings")));
const NotFound = lazy(() => wait(1000).then(() => import("../pages/NotFound")));
const StationList = lazy(() =>
  wait(1000).then(() => import("../pages/StationList"))
);
const StationDetail = lazy(() =>
  wait(1000).then(() => import("../pages/StationDetail"))
);

const StationAdd = lazy(() =>
  wait(1000).then(() => import("../pages/StationAdd"))
);
const TrainList = lazy(() =>
  wait(1000).then(() => import("../pages/TrainList"))
);
const TrainDetail = lazy(() =>
  wait(1000).then(() => import("../pages/TrainDetail"))
);

const TrainAdd = lazy(() => wait(1000).then(() => import("../pages/TrainAdd")));

const Users = lazy(() => wait(1000).then(() => import("../pages/Users")));
const AddUsers = lazy(() => wait(1000).then(() => import("../pages/AddUsers")));
const DetailUsers = lazy(() =>
  wait(1000).then(() => import("../pages/DetailUsers"))
);

const Routes = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routex>
        <Route element={<Dashboard />} path="/" />
        <Route element={<Dashboard />} path="/dashboard" />
        <Route element={<TrainList />} path="/train" />
        <Route element={<TrainDetail />} path="/train/detail/:id" />
        <Route element={<TrainAdd />} path="/train/add" />
        <Route element={<StationList />} path="/station" />
        <Route element={<StationDetail />} path="/station/detail/:id" />
        <Route element={<StationAdd />} path="/station/add" />
        <Route element={<MediaStorage />} path="/mediastorage" />
        <Route element={<MediaStorageAdd />} path="/mediastorage/add" />
        <Route element={<MediaStorageAdd />} path="/mediastorage/edit/:id" />

        <Route element={<Routen />} path="/routes" />
        <Route element={<RoutenAdd />} path="/routes/add" />
        <Route element={<RoutenDetail />} path="/routes/detail/:id" />
        <Route element={<Users />} path="/users" />
        <Route element={<AddUsers />} path="/users/add" />
        <Route element={<DetailUsers />} path="/users/detail/:id" />
        <Route element={<Clients />} path="/clients" />
        <Route element={<ClientsDetail />} path="/client/add" />
        <Route element={<ClientsDetail />} path="/client/edit/:id" />
        <Route element={<Settings />} path="/settings" />
        <Route element={<NotFound />} path="*" />
      </Routex>
    </Suspense>
  );
};

function wait(time) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export default Routes;
