import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loading from "../components/Loading";
const Login = lazy(() => wait(1000).then(() => import("../pages/Auth/Login")));
const NotFound = lazy(() => wait(1000).then(() => import("../pages/NotFound")));

const UnRoutes = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route element={<Login />} path="/" />
        <Route element={<NotFound />} path="*" />
      </Routes>
    </Suspense>
  );
};

function wait(time) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export default UnRoutes;
