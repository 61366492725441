import React from "react";

const Loading = () => {
  return (
    <div className="w-full h-full flex items-center justify-center ">
      <div className="flex flex-col items-center gap-2">
        <span className="h-10 w-10 block rounded-full border-8 border-t-PRIMARY animate-spin"></span>
        <h1>Wait a Second....</h1>
      </div>
    </div>
  );
};

export default Loading;
